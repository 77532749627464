import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { setLGMCattleliveCattleTargetWeight } from "../features/LGMSlice";
import { LGMQuoteType } from "@agdeveloper/domain";

function LGMCattleLiveCattleTargetWeight({
  DEFAULT_VALUES,
  TARGET_WEIGHT_MAP,
}) {
  const dispatch = useAppDispatch();
  const { type, cattle_live_cattle_target_weight } = useAppSelector(
    (state) => state.lgm.input,
  );

  const [targetWeight, setTargetWeight] = useState(DEFAULT_VALUES[type.value]);
  const [warningMessage, setWarningMessage] = useState("");
  const [touched, setTouched] = useState(false);

  const updateLiveCattleTargetWeight = (event) => {
    setTouched(true);

    const val = event.target.value.replace(/[^0-9.]/g, "");
    setTargetWeight(val);
  };

  useEffect(() => {
    setTargetWeight(DEFAULT_VALUES[type.value]);
  }, [type]);

  useEffect(() => {
    dispatch(setLGMCattleliveCattleTargetWeight(targetWeight));
  }, [targetWeight, dispatch]);

  useEffect(() => {
    const currentTargetWeight = parseFloat(
      cattle_live_cattle_target_weight.toString(),
    );
    if (
      (currentTargetWeight < TARGET_WEIGHT_MAP[type.value]["min"] ||
        currentTargetWeight > TARGET_WEIGHT_MAP[type.value]["max"]) &&
      touched
    ) {
      setWarningMessage(
        `Target Weight must be between ${TARGET_WEIGHT_MAP[type.value]["min"]} and ${TARGET_WEIGHT_MAP[type.value]["max"]} CWT.`,
      );
    } else {
      setWarningMessage("");
    }
    // dispatch(setCWT(current_CWT.toFixed(2)));
  }, [cattle_live_cattle_target_weight, dispatch, type]);

  return (
    <div className="card w-100">
      <div style={{ minWidth: 300 }}>
        <div className="card-body">
          <h5 className="card-title">Live Cattle Target Weight Quantity</h5>
          <p className="card-text">
            Enter a live cattle target weight between{" "}
            {TARGET_WEIGHT_MAP[type.value]["min"]} and{" "}
            {TARGET_WEIGHT_MAP[type.value]["max"]} CWT. (default:{" "}
            {DEFAULT_VALUES[type.value]})
          </p>
          <div className="input-group has-validation">
            <input
              type="text"
              name="cwt"
              id="cwt"
              className={`form-control ag-form-focus ${
                warningMessage !== "" && "is-invalid"
              }`}
              value={cattle_live_cattle_target_weight}
              onChange={updateLiveCattleTargetWeight}
            />
            <span className="input-group-text">CWT</span>
            <div className="invalid-feedback">
              <p>{warningMessage}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LGMCattleLiveCattleTargetWeight;
