import React from "react";
import { useAppDispatch } from "../../../../../app/redux/hooks";
import { setChartData } from "../../../features/DRPSlice";

const property_label_mappings = [
  { property: "class3_settle", label: "Class III" },
  { property: "class4_settle", label: "Class IV" },
  { property: "butter_settle", label: "Butterfat" },
  { property: "cheese_settle", label: "Protein" },
  { property: "whey_settle", label: "Other Solids" },
  { property: "nonfat_settle", label: "Nonfat Solids" },
  // {property: "expected_yield", label: "Milk Yield"},
  // {property: "yield_per_day", label: "Projected lbs per cow"},
];

function PriceTable({ quarterLabels, quarters }) {
  const dispatch = useAppDispatch();

  const updateData = (quarter, product) => {
    dispatch(
      setChartData({
        quarter: quarter,
        product: product,
      }),
    );
  };

  return (
    <div className="table-responsive">
      {/* Price Table */}
      <table className="table table-striped mt-2">
        <thead>
          <tr>
            <th></th>
            {quarterLabels.map((quarter_name, index) => {
              return (
                <th key={index} scope="col">
                  {quarter_name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {/* Prices */}
          {property_label_mappings.map((item, label_index) => {
            return (
              <tr key={label_index}>
                <th scope="row">{item.label}</th>
                {quarters.map((quarter, quarter_index) => {
                  return (
                    <td key={quarter_index}>
                      {quarter[item.property] === "-" ? (
                        quarter[item.property]
                      ) : (
                        <>
                          <button
                            type="button"
                            className="quarter-price-button"
                            data-bs-toggle="modal"
                            data-bs-target={`#chartModal`}
                            onClick={() =>
                              updateData(quarter["textstring"], item.label)
                            }
                          >
                            {quarter[item.property]}
                          </button>
                        </>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {/* Milk Yields */}
          <tr>
            <th scope="row">
              Milk Yield
              <br />
              <span className="small">
                <small>Projected lbs per cow</small>
              </span>
            </th>
            {quarters.map((quarter, index) => {
              return (
                <td key={index}>
                  <span className="fw-bold">{quarter["expected_yield"]}</span>
                  <br />
                  <span className="small">
                    {!isNaN(quarter["yield_per_day"]) &&
                    quarter["yield_per_day"] !== null &&
                    quarter["yield_per_day"] !== undefined
                      ? `${quarter["yield_per_day"]} / day`
                      : "-"}
                  </span>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export const MemoizedPriceTable = React.memo(PriceTable);
