import React, { useState, useEffect, useRef, useReducer } from "react";
import ResultTableIndemnitySingle from "./ResultTableIndemnitySingle/ResultTableIndemnitySingle";
// eslint-disable-next-line max-len
import ResultTableIndemnitySingleCVC from "./ResultTableIndemnitySingle/ResultTableIndemnitySingleCVC";
// eslint-disable-next-line max-len
import ResultTableIndemnityClassVsComponent from "./ResultTableIndemnityClassVsComponent/ResultTableIndemnityClassVsComponent";
import { useAppSelector, useAppDispatch } from "../../../../app/redux/hooks";
import {
  setIndemnityBasePrices,
  setProjectedPrices,
} from "../../features/DRPSlice";
import {
  expected_butter_price,
  expected_butter_price_c4,
  expected_class3_price,
  expected_class4_price,
  expected_nonfatsolids_price,
  expected_othersolids_price,
  expected_protein_price,
} from "./indemnity_methods";
import useInterval from "../../../../app/utils/hooks/useInterval";
// test
const drp = {
  // BUTTER_MAKE_ALLOWANCE: 0.1715,
  BUTTER_MAKE_ALLOWANCE: 0.2272,
  BUTTER_MANUFACTURING_YIELD: 1.211,
  // CHEESE_MAKE_ALLOWANCE: 0.2003,
  CHEESE_MAKE_ALLOWANCE: 0.2519,
  CHEESE_MANUFACTURING_YIELD_CASEIN: 1.383,
  // CHEESE_MANUFACTURING_YIELD_BUTTERFAT: 1.572,
  CHEESE_MANUFACTURING_YIELD_BUTTERFAT: 1.589,
  // BUTTERFAT_RETENTION_RATE: 0.9,
  BUTTERFAT_RETENTION_RATE: 0.91,
  BUTTERFAT_TO_PROTEIN_RATIO: 1.17,
  // DRY_WHEY_MAKE_ALLOWANCE: 0.1991,
  DRY_WHEY_MAKE_ALLOWANCE: 0.2668,
  DRY_WHEY_MANUFACTURING_YIELD: 1.03,
  // NONFAT_MAKE_ALLOWANCE: 0.1678,
  NONFAT_MAKE_ALLOWANCE: 0.2393,
  NONFAT_MANUFACTURING_YIELD: 0.99,
  SOLIDS_MULTIPLIER_CLASS: 5.9,
  SOLIDS_MULTIPLIER_COMPONENT: 5.7, // DRP policy page 3 - fixed at 5.7
  BUTTERFAT_PERCENTAGE: 3.5,
  PROTEIN_PERCENTAGE: 3.1,
  CLASS3_MULTIPLIER: 0.965,
  CLASS4_MULTIPLIER: 0.965,
  NONFAT_SOLIDS_MULTIPLIER: 9,
  MIN_BUTTER_VALUE: 0.001,
  MIN_PROTEIN_VALUE: 0.001,
  MIN_OTHERSOLIDS_VALUE: 0.001,
  MIN_NONFATSOLIDS_VALUE: 0.001,
  MIN_CLASS3_VALUE: 0.01,
  MIN_CLASS4_VALUE: 0.01,
};

const CLASS3_slider_consts = {
  min: -500,
  max: 400,
  inc: 0.01,
  default_slider: 0,
};

const CLASS4_slider_consts = {
  min: -500,
  max: 400,
  inc: 0.01,
  default_slider: 0,
};

const BUTTER_slider_consts = {
  min: -300,
  max: 300,
  inc: 0.01,
  default_slider: 0,
};

const PROTEIN_slider_consts = {
  min: -200,
  max: 200,
  inc: 0.01,
  default_slider: 0,
};

// fix 3 - angular has -1000 to 1000 in costs as this did but the actual
// slider in the html is using a hardcoded - 120 to 120 and not the
// defined const declared as it should
const OTHER_slider_consts = {
  min: -120,
  max: 120,
  inc: 0.001,
  default_slider: 0,
};

const NONFATSOLIDS_slider_consts = {
  min: -1000,
  max: 800,
  inc: 0.001,
  default_slider: 0,
};

const YAF_slider_consts = {
  min: -50,
  max: 50,
  inc: 0.1,
  default_slider: 0,
};

const BUTTERFAT_NONFAT_RATIO = 2;
// const NONFAT_FLIPPER = 0;
// const OTHERSOLIDS_TO_BUTTERFATPROTEIN_RATIO = 0.05;  // 20:1 put more
// class 3 price changes into BF/protein than other solids

// don't put any changes into other solids; just use it as a fudge space
// to balance the equation
const OTHERSOLIDS_TO_BUTTERFATPROTEIN_RATIO = 0.0;
const initalState = {
  incrButtonDown: false,
  targetSlider: "",
  incrementBy: 1,
};
const localReducer = (state, action) => {
  if (action.type === "start") {
    return {
      incrButtonDown: true,
      targetSlider: action.slider,
      incrementBy: action.incrementBy,
    };
  }
  if (action.type === "stop") {
    return {
      incrButtonDown: false,
      targetSlider: "",
      incrementBy: 1,
    };
  }

  throw Error("Unknown action.");
};
function ResultTableIndemnitiesParent({ flag }) {
  const [state, localDispatch] = useReducer(localReducer, initalState);

  const dispatch = useAppDispatch();
  const { drpQuoteResults, quarterPriceList, indemnityBasePrices } =
    useAppSelector((state) => state.drp);

  // Slider States
  const [class3Slider, setClass3Slider] = useState<number>(
    CLASS3_slider_consts.default_slider,
  );
  const [class4Slider, setClass4Slider] = useState<number>(
    CLASS4_slider_consts.default_slider,
  );
  const [butterSlider, setButterSlider] = useState<number>(
    BUTTER_slider_consts.default_slider,
  );
  const [proteinSlider, setProteinSlider] = useState<number>(
    PROTEIN_slider_consts.default_slider,
  );
  const [otherSolidsSlider, setOtherSolidsSlider] = useState<number>(
    OTHER_slider_consts.default_slider,
  );
  const [nonfatSolidsSlider, setNonfatSolidsSlider] = useState<number>(
    NONFATSOLIDS_slider_consts.default_slider,
  );
  const [yafSlider, setYafSlider] = useState<number>(
    YAF_slider_consts.default_slider,
  );
  // const [incrButtonDown, setIncrButtonDown] = useState<boolean>(false);
  // -------------------GROUP START -----------------------
  useInterval(() => {
    if (state.incrButtonDown && state.targetSlider) {
      incrementSlider(state.targetSlider, state.incrementBy);
    }
    // Set interval to run while button is pressed.
  }, [state.incrButtonDown, state.targetSlider]);
  // -------------------GROUP END -----------------------
  // Refs to hold previous slider values
  const oldClass3Slider = useRef(CLASS3_slider_consts.default_slider);
  const oldClass4Slider = useRef(CLASS4_slider_consts.default_slider);

  // Refs to hold other values
  // const NONFAT_FLIPPER = useRef(0);
  const new_projected_class4_price = useRef(indemnityBasePrices.class4Price);
  const new_projected_nonfat_solids_price = useRef(
    indemnityBasePrices.nonfatPrice,
  );
  const new_projected_protein_price = useRef(indemnityBasePrices.proteinPrice);

  const [nonfatFlipper, setNonfatFlipper] = useState(0);

  // Projected Price States
  const [projectedClass3Price, setProjectedClass3Price] = useState<number>(0);
  const [projectedClass4Price, setProjectedClass4Price] = useState<number>(0);
  const [projectedButterPrice, setProjectedButterPrice] = useState<number>(0);
  const [projectedProteinPrice, setProjectedProteinPrice] = useState<number>(0);
  const [projectedOthersolidsPrice, setProjectedOthersolidsPrice] =
    useState<number>(0);
  const [projectedNonfatSolidsPrice, setProjectedNonfatSolidsPrice] =
    useState<number>(0);

  // Yield Adjustment Factor
  const [yieldAdjustFactor, setYieldAdjustFactor] = useState<number>(0);
  const [currentYield, setCurrentYield] = useState<number>(0);
  const [roundedYieldAdjustment, setRoundedYieldAdjustment] =
    useState<number>(0);
  const [daysInQtr, setDaysInQtr] = useState<number>(0);
  // eslint-disable-next-line no-unused-vars
  const [_, forceRender] = useState<number>(0);
  useEffect(() => {
    forceRender((prev) => prev + 1);
  }, [projectedClass4Price]);
  // Other
  const [c3c4Lock, setC3c4Lock] = useState(true);
  const [lockButterProtein, setLockButterProtein] = useState(true);

  const handleLock = (type: string) => {
    if (type === "class") {
      setC3c4Lock((prevState) => !prevState);
    } else if (type === "comp") {
      setLockButterProtein((prevState) => !prevState);
    }
  };

  const _roundValue = (value: number, decimals: number = 4) => {
    return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  };

  const onChangeClass4 = (
    class4_slider,
    new_proj_class4_price,
    new_proj_nonfat_solids_price,
    diffnonfatsolids,
    // forceRender
  ) => {
    // Use setTimeout to introduce the delay
    const timeoutId = setTimeout(() => {
      setClass4Slider(class4_slider);
      setProjectedClass4Price(_roundValue(new_proj_class4_price, 2));
      setProjectedNonfatSolidsPrice(_roundValue(new_proj_nonfat_solids_price));
      setNonfatSolidsSlider(diffnonfatsolids);

      forceRender((prev) => prev + 1);
    }, 10);

    // Cleanup the timeout if necessary
    return () => clearTimeout(timeoutId);
  };
  // const update_projected_class3 = (slider_pos: number) => {
  //   const newClass3 =
  //     parseFloat(indemnityBasePrices.class3Price) +
  //     slider_pos * CLASS3_slider_consts.inc;
  //   setProjectedClass3Price(newClass3.toFixed(2));
  // };

  useEffect(() => {
    dispatch(
      setProjectedPrices({
        projectedClass3Price: projectedClass3Price,
        projectedClass4Price: projectedClass4Price,
        projectedButterPrice: projectedButterPrice,
        projectedProteinPrice: projectedProteinPrice,
        projectedNonfatSolidsPrice: projectedNonfatSolidsPrice,
        projectedOthersolidsPrice: projectedOthersolidsPrice,
        yieldAdjustFactor: yieldAdjustFactor,
      }),
    );
  }, [
    projectedClass3Price,
    projectedClass4Price,
    projectedButterPrice,
    projectedProteinPrice,
    projectedOthersolidsPrice,
    projectedNonfatSolidsPrice,
    yieldAdjustFactor,
    dispatch,
  ]);
  // Slider Methods
  // ONLY ROUND OUR SLIDER VALUES IN HERE

  const resetAllProjected = () => {
    setProjectedClass3Price(indemnityBasePrices.class3Price);
    setProjectedClass4Price(indemnityBasePrices.class4Price);
    setProjectedButterPrice(indemnityBasePrices.butterfatPrice);
    setProjectedNonfatSolidsPrice(indemnityBasePrices.nonfatPrice);
    setProjectedOthersolidsPrice(indemnityBasePrices.otherSolidsPrice);
    setProjectedProteinPrice(indemnityBasePrices.proteinPrice);
  };

  const update_class4 = (projected_butter, projected_nonfat) => {
    // Called by butter, protein, class 3
    const newc4 = expected_class4_price(
      projected_nonfat,
      projected_butter,
      drp,
    );
    const diffc4 = Math.round(
      (newc4 - indemnityBasePrices.class4Price) / CLASS4_slider_consts.inc,
    );

    setClass4Slider(diffc4);

    setProjectedClass4Price(
      _roundValue(
        indemnityBasePrices.class4Price + diffc4 * CLASS4_slider_consts.inc,
        2,
      ),
    );
  };

  // Update class4 whenever one of the dependant states change
  // useEffect(update_class4, [
  //   projectedNonfatSolidsPrice,
  //   projectedButterPrice,
  //   indemnityBasePrices.class4Price,
  // ]);

  const updateFromYAFSlider_c3c4 = (newSliderValue: number) => {
    const yield_adjustment_factor = newSliderValue * YAF_slider_consts.inc;
    setYafSlider(newSliderValue);
    setYieldAdjustFactor(_roundValue(yield_adjustment_factor, 1));
  };

  const updateFromYAFSlider_comp = (newSliderValue: number) => {
    const yield_adjustment_factor = yafSlider * YAF_slider_consts.inc;
    setYafSlider(newSliderValue);
    setYieldAdjustFactor(_roundValue(yield_adjustment_factor, 1));
  };

  const updateFromClass3Slider_c3c4 = (newSliderValue: number) => {
    const oldClass3 = projectedClass3Price;
    const newClass3 =
      indemnityBasePrices.class3Price +
      newSliderValue * CLASS3_slider_consts.inc;

    setClass3Slider(newSliderValue);
    setProjectedClass3Price(_roundValue(newClass3, 2));
    const diffpercent = (newClass3 - oldClass3) / oldClass3;

    if (c3c4Lock) {
      // Then update the same for Class 4 if c3c4Lock.
      const newc4 = Math.max(
        CLASS4_slider_consts.min,
        projectedClass4Price + diffpercent * projectedClass4Price,
      );

      const diffc4 = Math.round(
        (newc4 - indemnityBasePrices.class4Price) / CLASS4_slider_consts.inc,
      );

      setClass4Slider(diffc4);
      setProjectedClass4Price(_roundValue(newc4, 2));
    }
  };

  const updateFromClass3Slider_comp = (newSliderValue: number) => {
    const oldClass3 = projectedClass3Price;

    // Step 1: Calc difference from previous
    // const slider_change = oldClass3Slider.current - newSliderValue;
    const slider_change = class3Slider - newSliderValue;

    const total_class3_price_change = slider_change * CLASS3_slider_consts.inc;

    setClass3Slider(newSliderValue);

    const butterfat_protein_change =
      total_class3_price_change * (1 - OTHERSOLIDS_TO_BUTTERFATPROTEIN_RATIO);

    const bffunc = slider_change > 0 ? Math.ceil : Math.floor;

    // TODO: Make sure these values are used only where needed
    let butterfat_change;
    let new_projected_class3_price = indemnityBasePrices.butterfatPrice;
    // let new_projected_butter_price = "0";
    // let new_projected_other_solids_price = "0";

    if (Math.abs(slider_change) > 1) {
      butterfat_change =
        Math.round(
          butterfat_protein_change *
            (drp.BUTTERFAT_TO_PROTEIN_RATIO /
              (drp.BUTTERFAT_TO_PROTEIN_RATIO + 1)) *
            1000,
        ) / 1000;
    } else {
      butterfat_change =
        bffunc(
          butterfat_protein_change *
            (drp.BUTTERFAT_TO_PROTEIN_RATIO /
              (drp.BUTTERFAT_TO_PROTEIN_RATIO + 1)) *
            1000,
        ) / 1000;
    }

    // const protein_change = butterfat_protein_change - butterfat_change;

    const newButterfatPrice =
      Math.round(
        expected_butter_price(
          projectedClass3Price - butterfat_change,
          projectedProteinPrice,
          projectedOthersolidsPrice,
          drp,
        ) * 1000,
      ) / 1000;

    const newProteinPrice =
      Math.round(
        expected_protein_price(
          projectedClass3Price - total_class3_price_change,
          newButterfatPrice,
          projectedOthersolidsPrice,
          drp,
        ) * 1000,
      ) / 1000;

    const newOtherSolidsPrice = expected_othersolids_price(
      projectedClass3Price - total_class3_price_change,
      newButterfatPrice,
      newProteinPrice,
      drp,
    );

    new_projected_class3_price =
      indemnityBasePrices.class3Price +
      newSliderValue * CLASS3_slider_consts.inc;
    setProjectedClass3Price(_roundValue(new_projected_class3_price, 2));

    const new_projected_butter_price = Math.max(
      drp.MIN_BUTTER_VALUE,
      newButterfatPrice,
    );
    setProjectedButterPrice(_roundValue(new_projected_butter_price));

    // update butter slider
    const diffbutter = Math.round(
      (newButterfatPrice - indemnityBasePrices.butterfatPrice) /
        BUTTER_slider_consts.inc,
    );

    setButterSlider(diffbutter);

    // Move update class 4 to effect

    update_class4(new_projected_butter_price, projectedNonfatSolidsPrice);

    setProjectedProteinPrice(
      _roundValue(Math.max(drp.MIN_PROTEIN_VALUE, newProteinPrice)),
    );

    const diffprotein = Math.round(
      (newProteinPrice - indemnityBasePrices.proteinPrice) /
        PROTEIN_slider_consts.inc,
    );
    setProteinSlider(diffprotein);

    // Adjust other solids
    if (new_projected_class3_price < oldClass3) {
      const new_projected_other_solids_price = Math.max(
        drp.MIN_OTHERSOLIDS_VALUE,
        Math.floor(newOtherSolidsPrice * 1000) / 1000,
      );
      setProjectedOthersolidsPrice(
        _roundValue(new_projected_other_solids_price),
      );
    } else {
      const new_projected_other_solids_price = Math.max(
        drp.MIN_OTHERSOLIDS_VALUE,
        Math.ceil(newOtherSolidsPrice * 1000) / 1000,
      );
      setProjectedOthersolidsPrice(
        _roundValue(new_projected_other_solids_price),
      );
    }
    // setProjectedOthersolidsPrice(new_projected_other_solids_price);

    const diffothersolids = Math.round(
      (newOtherSolidsPrice - indemnityBasePrices.otherSolidsPrice) /
        OTHER_slider_consts.inc,
    );

    setOtherSolidsSlider(diffothersolids);
  };

  const updateFromClass4Slider_comp = (newSliderValue: number) => {
    // const total_ticks = Math.abs(class4Slider - oldClass4Slider.current);
    // const tick_direction = Math.sign(class4Slider - oldClass4Slider.current);

    const old_class4_slider = class4Slider;
    const oldclass4 = projectedClass4Price;

    const total_ticks = Math.abs(newSliderValue - old_class4_slider);
    const tick_direction = Math.sign(newSliderValue - old_class4_slider);

    let butter_ticks =
      Math.max(
        1,
        Math.round(total_ticks / (BUTTERFAT_NONFAT_RATIO + 1)) *
          BUTTERFAT_NONFAT_RATIO,
      ) * tick_direction;
    let nonfat_tick =
      Math.max(0, total_ticks - Math.abs(butter_ticks)) * tick_direction;

    // nonfat_tick = nonfat_tick === -0 ? 0 : nonfat_tick;
    nonfat_tick = Object.is(nonfat_tick, -0) ? 0 : nonfat_tick;

    // FIXME: These should be refs handled globally or initialized
    // to indemnity base price values

    console.info(total_ticks);
    console.info(BUTTERFAT_NONFAT_RATIO);

    // NONFAT_FLIPPER.current =
    //   NONFAT_FLIPPER.current + (total_ticks % BUTTERFAT_NONFAT_RATIO);

    const newFlipper = nonfatFlipper + (total_ticks % BUTTERFAT_NONFAT_RATIO);
    setNonfatFlipper(newFlipper);

    if (Math.abs(nonfat_tick) > 0) {
      // jump on slider
      // move class 4 price and calc nonfat

      const class4_slider = class4Slider + nonfat_tick;

      new_projected_class4_price.current =
        indemnityBasePrices.class4Price +
        class4_slider * CLASS4_slider_consts.inc;

      // Adjust nonfat solids
      const newNonfatSolids = expected_nonfatsolids_price(
        new_projected_class4_price.current,
        projectedButterPrice,
        drp,
      );

      // Set nonfatsolids base price
      new_projected_nonfat_solids_price.current = Math.max(
        drp.MIN_NONFATSOLIDS_VALUE,
        newNonfatSolids,
      );
      const diffnonfatsolids = Math.round(
        (newNonfatSolids - indemnityBasePrices.nonfatPrice) /
          NONFATSOLIDS_slider_consts.inc,
      );

      onChangeClass4(
        class4_slider,
        new_projected_class4_price.current,
        new_projected_nonfat_solids_price.current,
        diffnonfatsolids,
      );
    } else {
      if (nonfatFlipper >= BUTTERFAT_NONFAT_RATIO) {
        const class4_slider = class4Slider + total_ticks * tick_direction;

        new_projected_class4_price.current =
          indemnityBasePrices.class4Price +
          class4_slider * CLASS4_slider_consts.inc;

        // adjust nonfat solids
        const newNonfatSolids = expected_nonfatsolids_price(
          new_projected_class4_price.current,
          projectedButterPrice,
          drp,
        );

        new_projected_nonfat_solids_price.current = Math.max(
          drp.MIN_NONFATSOLIDS_VALUE,
          newNonfatSolids,
        );

        const diffnonfatsolids = Math.round(
          (newNonfatSolids - indemnityBasePrices.nonfatPrice) /
            NONFATSOLIDS_slider_consts.inc,
        );

        onChangeClass4(
          class4_slider,
          new_projected_class4_price.current,
          new_projected_nonfat_solids_price.current,
          diffnonfatsolids,
        );
        setNonfatFlipper(0);
        butter_ticks = 0;
      } else {
        console.log("If youre seeing this you shouldnt be.");
      }
    }

    const class4_slider = class4Slider + butter_ticks;
    setClass4Slider(class4_slider);

    // move class 4 price and calc butter
    // QUESTION: Why is class4 slider and projected price updated here as well?
    new_projected_class4_price.current =
      indemnityBasePrices.class4Price +
      class4_slider * CLASS4_slider_consts.inc;

    setProjectedClass4Price(_roundValue(new_projected_class4_price.current, 2));

    const calcButter = expected_butter_price_c4(
      new_projected_class4_price.current,
      new_projected_nonfat_solids_price.current,
      drp,
    );

    let butterfunc: Function;

    if (new_projected_class4_price.current < oldclass4) {
      butterfunc = Math.min;
    } else {
      butterfunc = Math.max;
    }

    const newButter = butterfunc(calcButter, projectedButterPrice);
    const diffbutter = Math.round(
      (newButter - indemnityBasePrices.butterfatPrice) /
        BUTTER_slider_consts.inc,
    );
    setButterSlider(diffbutter);

    let new_projected_butter_price = Math.max(newButter, drp.MIN_BUTTER_VALUE);
    setProjectedButterPrice(_roundValue(new_projected_butter_price));

    if (lockButterProtein) {
      const newProtein = expected_protein_price(
        projectedClass3Price,
        new_projected_butter_price,
        projectedOthersolidsPrice,
        drp,
      );

      // move the protein slider
      const diffprotein = Math.round(
        (newProtein - indemnityBasePrices.proteinPrice) /
          PROTEIN_slider_consts.inc,
      );

      setProteinSlider(diffprotein);

      new_projected_protein_price.current = Math.max(
        drp.MIN_PROTEIN_VALUE,
        indemnityBasePrices.proteinPrice +
          diffprotein * PROTEIN_slider_consts.inc,
      );

      setProjectedProteinPrice(
        _roundValue(new_projected_protein_price.current),
      );
    } else {
      // update class 3
      // put this difference into class 3
      const newc3 = expected_class3_price(
        projectedProteinPrice,
        new_projected_butter_price,
        projectedOthersolidsPrice,
        drp,
      );

      const diffc3 = Math.round(
        (newc3 - indemnityBasePrices.class3Price) / CLASS3_slider_consts.inc,
      );

      setClass3Slider(diffc3);

      const new_projected_class3_price =
        indemnityBasePrices.class3Price + diffc3 * CLASS3_slider_consts.inc;
      setProjectedClass3Price(_roundValue(new_projected_class3_price, 2));
    }
  };

  //////////////////////
  const updateFromButterSlider = (newSliderValue: number) => {
    // Called when butter slider is updated
    // TODO: class 3 price not being correctly updated when this function is called

    let projected_butter_price = indemnityBasePrices.butterfatPrice;

    setButterSlider(newSliderValue);

    projected_butter_price = Math.max(
      drp.MIN_BUTTER_VALUE,
      indemnityBasePrices.butterfatPrice +
        newSliderValue * BUTTER_slider_consts.inc,
    );

    setProjectedButterPrice(_roundValue(projected_butter_price));

    if (lockButterProtein) {
      const newProtein = expected_protein_price(
        projectedClass3Price,
        projected_butter_price,
        projectedOthersolidsPrice,
        drp,
      );

      const diffprotein = Math.round(
        (newProtein - indemnityBasePrices.proteinPrice) /
          PROTEIN_slider_consts.inc,
      );

      setProteinSlider(diffprotein);

      const projected_protein_price = Math.max(
        drp.MIN_PROTEIN_VALUE,
        indemnityBasePrices.proteinPrice +
          diffprotein * PROTEIN_slider_consts.inc,
      );

      setProjectedProteinPrice(_roundValue(projected_protein_price));

      /*
        We still need to re-solve for class3 price here, since it's
        affected by changes to butter/protein.
      */
      const newc3 = expected_class3_price(
        projected_protein_price,
        projected_butter_price,
        projectedOthersolidsPrice,
        drp,
      );
      // console.debug(`New c3 price: ${newc3}`);
      setProjectedClass3Price(_roundValue(newc3, 2));
    } else {
      const newc3 = expected_class3_price(
        projectedProteinPrice,
        projected_butter_price,
        projectedOthersolidsPrice,
        drp,
      );

      const diffc3 = Math.round(
        (newc3 - indemnityBasePrices.class3Price) / CLASS3_slider_consts.inc,
      );

      setClass3Slider(diffc3);

      const projected_class3_price =
        indemnityBasePrices.class3Price + diffc3 * CLASS3_slider_consts.inc;

      console.debug("setting projected class3 price...");
      setProjectedClass3Price(_roundValue(projected_class3_price, 2));
    }

    // update class4 price, since it depends on butterfat price
    update_class4(projected_butter_price, projectedNonfatSolidsPrice);
  };

  //////////////////////

  const updateFromProteinSlider = (newSliderValue: number) => {
    // Called when protein slider is updated
    let projected_protein_price = indemnityBasePrices.proteinPrice;

    setProteinSlider(newSliderValue);

    projected_protein_price = Math.max(
      drp.MIN_PROTEIN_VALUE,
      indemnityBasePrices.proteinPrice +
        newSliderValue * PROTEIN_slider_consts.inc,
    );
    setProjectedProteinPrice(_roundValue(projected_protein_price));

    if (lockButterProtein) {
      const newButter = expected_butter_price(
        projectedClass3Price,
        projected_protein_price,
        projectedOthersolidsPrice,
        drp,
      );

      const diffbutter = Math.round(
        (newButter - indemnityBasePrices.butterfatPrice) /
          BUTTER_slider_consts.inc,
      );

      setButterSlider(diffbutter);

      let projected_butter_price = Math.max(
        drp.MIN_BUTTER_VALUE,
        indemnityBasePrices.butterfatPrice +
          diffbutter * BUTTER_slider_consts.inc,
      );

      setProjectedButterPrice(_roundValue(projected_butter_price));
      update_class4(projected_butter_price, projectedNonfatSolidsPrice);
    } else {
      const newc3 = expected_class3_price(
        projected_protein_price,
        projectedButterPrice,
        projectedOthersolidsPrice,
        drp,
      );

      //fix for class 3 glitching - needed () around the subtraction
      const diffc3 = Math.round(
        (newc3 - indemnityBasePrices.class3Price) / CLASS3_slider_consts.inc,
      );
      setClass3Slider(diffc3);

      const projected_class3_price =
        indemnityBasePrices.class3Price + diffc3 * CLASS3_slider_consts.inc;
      setProjectedClass3Price(_roundValue(projected_class3_price, 2));
    }
  };

  const updateFromOtherSolidsSlider = (newSliderValue: number) => {
    let projected_other_solids_price = indemnityBasePrices.otherSolidsPrice;

    setOtherSolidsSlider(newSliderValue);

    projected_other_solids_price = Math.max(
      drp.MIN_OTHERSOLIDS_VALUE,
      indemnityBasePrices.otherSolidsPrice +
        newSliderValue * OTHER_slider_consts.inc,
    );

    setProjectedOthersolidsPrice(_roundValue(projected_other_solids_price));

    const newc3 = expected_class3_price(
      projectedProteinPrice,
      projectedButterPrice,
      projected_other_solids_price,
      drp,
    );

    const diffc3 = Math.round(
      (newc3 - indemnityBasePrices.class3Price) / CLASS3_slider_consts.inc,
    );

    setClass3Slider(diffc3);

    let projected_class3_price =
      indemnityBasePrices.class3Price + diffc3 * CLASS3_slider_consts.inc;

    setProjectedClass3Price(_roundValue(projected_class3_price, 2));
  };

  const updateFromNonfatSolidsSlider = (newSliderValue: number) => {
    let projected_nonfat_solids_price = indemnityBasePrices.nonfatPrice;

    setNonfatSolidsSlider(newSliderValue);

    projected_nonfat_solids_price = Math.max(
      drp.MIN_NONFATSOLIDS_VALUE,
      indemnityBasePrices.nonfatPrice +
        newSliderValue * NONFATSOLIDS_slider_consts.inc,
    );
    setProjectedNonfatSolidsPrice(_roundValue(projected_nonfat_solids_price));

    const newc4 = expected_class4_price(
      projected_nonfat_solids_price,
      projectedButterPrice,
      drp,
    );
    const diffc4 = Math.round(
      (newc4 - indemnityBasePrices.class4Price) / CLASS4_slider_consts.inc,
    );

    setClass4Slider(diffc4);

    let projected_class4_price =
      indemnityBasePrices.class4Price + diffc4 * CLASS4_slider_consts.inc;

    setProjectedClass4Price(_roundValue(projected_class4_price, 2));
  };

  const updateFromClass4Slider_c3c4 = (newSliderValue: number) => {
    const oldclass4 = projectedClass4Price;
    const newclass4 =
      indemnityBasePrices.class4Price +
      newSliderValue * CLASS4_slider_consts.inc;

    setClass4Slider(newSliderValue);
    setProjectedClass4Price(_roundValue(newclass4, 2));
    const diffpercent = (newclass4 - oldclass4) / oldclass4;

    if (c3c4Lock) {
      // Then update the same for Class 3 if c3c4Lock.
      const newc3 = Math.max(
        CLASS3_slider_consts.min,
        projectedClass3Price + diffpercent * projectedClass3Price,
      );

      const diffc3 = Math.round(
        (newc3 - indemnityBasePrices.class3Price) / CLASS3_slider_consts.inc,
      );

      setClass3Slider(diffc3);
      setProjectedClass3Price(_roundValue(newc3, 2));
    }
  };

  const incrementSlider = (slider: string, sliderValue: number) => {
    let func;

    switch (slider) {
      case "Class III - Class":
        func = (value) => {
          updateFromClass3Slider_c3c4(class3Slider + value);
        };
        break;
      case "Class IV - Class":
        func = (value) => {
          // setClass4Slider((prevVal) => prevVal + Number(value));
          updateFromClass4Slider_c3c4(class4Slider + value);
        };
        break;
      case "Yield Adj - Class":
        func = (value) => {
          // setYafSlider((prevVal) => prevVal + Number(value));
          updateFromYAFSlider_c3c4(yafSlider + value);
        };
        break;
      case "Class III - Comp":
        func = (value) => {
          // setClass3Slider((prevVal) => prevVal + Number(value));
          updateFromClass3Slider_comp(class3Slider + value);
        };
        break;
      case "Class IV - Comp":
        func = (value) => {
          // setClass4Slider((prevVal) => prevVal + Number(value));
          updateFromClass4Slider_comp(class4Slider + value);
        };
        break;
      case "Butterfat - Comp":
        func = (value) => {
          // setButterSlider((prevVal) => prevVal + Number(value));
          updateFromButterSlider(butterSlider + value);
        };
        break;
      case "Protein - Comp":
        func = (value) => {
          // setProteinSlider((prevVal) => prevVal + Number(value));
          updateFromProteinSlider(proteinSlider + value);
        };
        break;
      case "Other Solids - Comp":
        func = (value) => {
          // setOtherSolidsSlider((prevVal) => prevVal + Number(value));
          updateFromOtherSolidsSlider(otherSolidsSlider + value);
        };
        break;
      case "Nonfat Solids - Comp":
        func = (value) => {
          // setNonfatSolidsSlider((prevVal) => prevVal + Number(value));
          updateFromNonfatSolidsSlider(nonfatSolidsSlider + value);
        };
        break;
      case "Yield Adj - Comp":
        func = (value) => {
          // setYieldAdjustFactor((prevVal) => prevVal + Number(value));
          updateFromYAFSlider_comp(yafSlider + value);
        };
        break;
      default:
        // eslint-disable-next-line no-shadow, no-unused-vars
        func = (_: number) => {};
    }

    func(Number(sliderValue));
  };

  const resetAllSliders = () => {
    oldClass3Slider.current = CLASS3_slider_consts.default_slider;
    oldClass4Slider.current = CLASS4_slider_consts.default_slider;
    // NONFAT_FLIPPER.current = 0;
    setNonfatFlipper(0);

    setClass3Slider(CLASS3_slider_consts.default_slider);
    setClass4Slider(CLASS4_slider_consts.default_slider);
    setButterSlider(BUTTER_slider_consts.default_slider);
    setProteinSlider(PROTEIN_slider_consts.default_slider);
    setOtherSolidsSlider(OTHER_slider_consts.default_slider);
    setNonfatSolidsSlider(NONFATSOLIDS_slider_consts.default_slider);
    // updateIndemnity();
  };

  const resetYafSlider = () => {
    setYafSlider(YAF_slider_consts.default_slider);
    setYieldAdjustFactor(0.0);
    // updateIndemnity();
  };

  const resetAllValues = () => {
    resetAllSliders();
    resetAllProjected();
    resetYafSlider();
  };

  const get_quarter_price = (price, decimal_places: number) => {
    // let value = Number(0).toFixed(decimal_places);
    // try {
    //   value = price; //.toFixed(decimal_places);
    //   return value;
    // } catch {
    //   return value;
    // }
    return price;
  };
  const _get_quarter = (data) => {
    if (flag === "single") {
      return data["quote_params"]["quarter"];
    } else if (flag === "class-vs-component") {
      return data["CLPROP"]["quote_params"]["quarter"];
    } else if (flag === "strip") {
      console.log(
        "WE HAVE NO SLIDERS FOR THIS YOU SHOULDNT SEE THIS AS RESULT " +
          "TABLE INDEMNITY SHOULDNT BE MOUNTED",
      );
      return undefined;
    }
  };
  useEffect(() => {
    if (drpQuoteResults.data) {
      const quarter = _get_quarter(drpQuoteResults.data);

      // TODO: Convert base prices to strings
      let classIII = 0;
      let classIV = 0;
      let butterfat = 0;
      let nonfat_solids = 0;
      let other_solids = 0;
      let protein = 0;

      let current_yield = 0;
      let days_in_qtr = 0;
      for (const index in quarterPriceList) {
        if (quarterPriceList[index]["qtr"] === quarter) {
          classIII = get_quarter_price(
            quarterPriceList[index]["ExpectedClassIIIPrice"],
            2,
          );
          classIV = get_quarter_price(
            quarterPriceList[index]["ExpectedClassIVPrice"],
            2,
          );

          // In the case of class weighting locked class 3/4 will belocked and thus must equal them
          classIII =
            typeof classIII === "number" ? classIII : classIV ? classIV : 15;
          classIV =
            typeof classIV === "number" ? classIV : classIII ? classIII : 15;

          butterfat = get_quarter_price(
            quarterPriceList[index]["ExpectedButterfatPrice"],
            3,
          );

          other_solids = get_quarter_price(
            quarterPriceList[index]["ExpectedOtherSolidsPrice"],
            3,
          );
          protein = get_quarter_price(
            quarterPriceList[index]["ExpectedProteinPrice"],
            3,
          );

          // fix 5 adding restricted nonfat logic
          const is_nonfat_locked =
            typeof quarterPriceList[index]["ExpectedNonfatSolidsPrice"] ===
            "number"
              ? false
              : true;

          // if nonfat is locked then set the expected nonfat price to the restricted value
          if (is_nonfat_locked) {
            const nfsp = expected_nonfatsolids_price(classIV, butterfat, drp);

            nonfat_solids = nfsp; // Math.round(nfsp * 10000) / 10000);
          } else {
            nonfat_solids = get_quarter_price(
              quarterPriceList[index]["ExpectedNonfatSolidsPrice"],
              3,
            );
          }

          current_yield = quarterPriceList[index]["ExpectedYield"];
          days_in_qtr = quarterPriceList[index]["days_in_qtr"];

          // Re-calculate values to match slider increments
          // butterfat = (Math.round(butterfat * 100) / 100)
          // protein = (Math.round(parseFloat(protein) * 100) / 100).toFixed(3);
          // other_solids = (
          //   Math.round(parseFloat(other_solids) * 100) / 100
          // ).toFixed(2);
          // nonfat_solids = (
          //   Math.round(parseFloat(nonfat_solids) * 100) / 100
          // ).toFixed(2);
        }
      }

      setCurrentYield(current_yield);
      setDaysInQtr(days_in_qtr);

      dispatch(
        setIndemnityBasePrices({
          class3Price: classIII,
          class4Price: classIV,
          butterfatPrice: butterfat,
          nonfatPrice: nonfat_solids,
          otherSolidsPrice: other_solids,
          proteinPrice: protein,
        }),
      );
    }

    // dont trigger with call of get quarter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drpQuoteResults.data]);

  // Calculate rounded yield when currentYield is set
  const calculateRoundedYield = () => {
    const rounded_yield: number = Math.round(
      currentYield * (1 + yieldAdjustFactor / 100),
    );

    setRoundedYieldAdjustment(rounded_yield);
  };
  useEffect(calculateRoundedYield, [currentYield, yieldAdjustFactor]);

  if (!drpQuoteResults.loading && drpQuoteResults.data) {
    // Display only if drp results are available
    const slider_consts = {
      class3: CLASS3_slider_consts,
      class4: CLASS4_slider_consts,
      butter: BUTTER_slider_consts,
      protein: PROTEIN_slider_consts,
      otherSolids: OTHER_slider_consts,
      nonfat: NONFATSOLIDS_slider_consts,
      yield: YAF_slider_consts,
    };

    const sliderValues = {
      class3Slider: class3Slider,
      oldClass3Slider: oldClass3Slider,
      class4Slider: class4Slider,
      oldClass4Slider: oldClass4Slider,
      butterSlider: butterSlider,
      proteinSlider: proteinSlider,
      otherSolidsSlider: otherSolidsSlider,
      nonfatSolidsSlider: nonfatSolidsSlider,
      yafSlider: yafSlider,
    };

    const sliderMethods = {
      setClass3Slider: setClass3Slider,
      setClass4Slider: setClass4Slider,
      setYafSlider: setYafSlider,
      setButterSlider: setButterSlider,
      setProteinSlider: setProteinSlider,
      setOtherSolidsSlider: setOtherSolidsSlider,
      setNonfatSolidsSlider: setNonfatSolidsSlider,

      // Other slider methods
      resetAllSliders: resetAllValues,
      resetYafSlider: resetYafSlider,
      incrementSlider: incrementSlider,
      startIncrementSlider: (slider: string, value: number) =>
        localDispatch({ type: "start", slider: slider, incrementBy: value }),
      stopSliderIncrement: () => localDispatch({ type: "stop" }),

      updateFromClass3Slider_c3c4: updateFromClass3Slider_c3c4,
      updateFromClass4Slider_c3c4: updateFromClass4Slider_c3c4,
      updateFromYAFSlider_c3c4: updateFromYAFSlider_c3c4,

      updateFromClass3Slider_comp: updateFromClass3Slider_comp,
      updateFromClass4Slider_comp: updateFromClass4Slider_comp,
      updateFromButterSlider: updateFromButterSlider,
      updateFromProteinSlider: updateFromProteinSlider,
      updateFromNonfatSolidsSlider: updateFromNonfatSolidsSlider,
      updateFromOtherSolidsSlider: updateFromOtherSolidsSlider,
      updateFromYAFSlider_comp: updateFromYAFSlider_comp,
    };

    const projectedPrices = {
      projectedClass3Price: projectedClass3Price,
      projectedClass4Price: projectedClass4Price,
      projectedButterPrice: projectedButterPrice,
      projectedProteinPrice: projectedProteinPrice,
      projectedNonfatSolidsPrice: projectedNonfatSolidsPrice,
      projectedOthersolidsPrice: projectedOthersolidsPrice,
    };
    //only used for download functioanlity
    const yieldValues = {
      yieldAdjustFactor: yieldAdjustFactor,
      currentYield: currentYield,
      roundedYieldAdjustment: roundedYieldAdjustment,
    };

    return (
      <div>
        {
          flag === "single" &&
            (drpQuoteResults.data["quote_params"]["quote_type"]["value"] ===
            "CLPROP" ? (
              <ResultTableIndemnitySingle
                slider_consts={slider_consts}
                sliderValues={sliderValues}
                sliderMethods={sliderMethods}
                projectedPrices={projectedPrices}
                yieldValues={yieldValues}
                daysInQtr={daysInQtr}
                c3c4Lock={c3c4Lock}
                lockButterProtein={lockButterProtein}
                handleLock={handleLock}
              />
            ) : (
              <ResultTableIndemnitySingleCVC
                slider_consts={slider_consts}
                sliderValues={sliderValues}
                sliderMethods={sliderMethods}
                projectedPrices={projectedPrices}
                yieldValues={yieldValues}
                daysInQtr={daysInQtr}
                c3c4Lock={c3c4Lock}
                lockButterProtein={lockButterProtein}
                handleLock={handleLock}
              />
              // <></>
            )) /* End of single quote */
        }
        {
          flag === "class-vs-component" && (
            <ResultTableIndemnityClassVsComponent
              slider_consts={slider_consts}
              sliderValues={sliderValues}
              sliderMethods={sliderMethods}
              projectedPrices={projectedPrices}
              yieldValues={yieldValues}
              daysInQtr={daysInQtr}
              c3c4Lock={c3c4Lock}
              lockButterProtein={lockButterProtein}
              handleLock={handleLock}
            />
          )
          /* End of class vs component quote */
        }
      </div>
    );
  }
  return <></>;
}

export default ResultTableIndemnitiesParent;

/*
FIXME:
    1. When adjusting sliders with slider lock enabled, sliders
    values are not consistent with Angular component.
*/
