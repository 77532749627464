/// <reference types="vite-plugin-svgr/client" />
import React, { useEffect } from "react";
import "./SideMenu.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { getAllUsersForAgent } from "../../Authentication/features/authActions";

import DRPCalculator from "../../app/assets/calculator_drp_black.svg?react";
import LRPCalculator from "../../app/assets/calculator_lrp_black.svg?react";
import LGMCalculator from "../../app/assets/calculator_lgm_black.svg?react";

import logo from "../../app/assets/csc_logo_transparent.png";
import blue_logo from "../../app/assets/csc_logo_transparent_blue.png";

function SideMenu({ toggleSidebar, isOpen }) {
  const dispatch = useAppDispatch();
  // const [isRegistrationComplete, setIsRegistrationComplete] =
  //   useState<boolean>(false);
  const {
    email,
    scopes,
    isLoggedIn,
    userType,
    agents_producers,
    registrationAuthorization,
    emailVerification,
    agencyProfile,
  } = useAppSelector((state) => state.auth);

  const scoped = (scope: string) => {
    // console.log(scopes);
    return scopes.includes(scope);
  };

  useEffect(() => {
    if (
      isLoggedIn &&
      registrationAuthorization.isRegistrationAuthorized &&
      emailVerification.isEmailVerified &&
      agencyProfile.isAgencyProfileComplete
    ) {
      // Get all users for agent to populate badge alert on sidebar before user navigates to Authorize users Page
      dispatch(getAllUsersForAgent());
    }

    // setIsRegistrationComplete(
    //   userType === "AGENT"
    //     ? isLoggedIn &&
    //         registrationAuthorization.isRegistrationAuthorized &&
    //         emailVerification.isEmailVerified &&
    //         agencyProfile.isAgencyProfileComplete
    //     : isLoggedIn &&
    //         registrationAuthorization.isRegistrationAuthorized &&
    //         emailVerification.isEmailVerified
    // );
  }, [
    isLoggedIn,
    registrationAuthorization.isRegistrationAuthorized,
    emailVerification.isEmailVerified,
    agencyProfile.isAgencyProfileComplete,
    dispatch,
  ]);
  const [unauthorized_users, setUnauthorizedUsers] = useState<Array<object>>(
    [],
  );
  const [hidden, setHidden] = useState<boolean>(true);
  // const [isLRPHovered, setIsLRPHovered] = useState<boolean>(false);
  // const [isLGMHovered, setIsLGMHovered] = useState<boolean>(false);
  // const [isDRPHovered, setIsDRPHovered] = useState<boolean>(false);

  useEffect(() => {
    isOpen
      ? setTimeout(() => {
          setHidden(false);
        }, 200)
      : setHidden(true);
  }, [isOpen]);

  useEffect(() => {
    if (agents_producers.length > 0) {
      setUnauthorizedUsers(
        agents_producers.filter((user) => {
          return user["StatusName"] === "UNAUTHORIZED";
        }),
      );
    }
  }, [agents_producers]);
  return (
    <div className={`sidebar ${isOpen && "open"}`}>
      <div className="nav-container">
        <div className="logo-details" onClick={toggleSidebar}>
          <img
            src={userType === "USER" ? String(blue_logo) : String(logo)}
            alt="CSC AG Logo"
          />
          <div className={`logo_name ${!isOpen && "hidden"}`}>
            AG Hedge Desk
          </div>
          <i
            className={`bx ${isOpen ? "bx-menu-alt-right" : "bx-menu"}`}
            id="btn"
          ></i>
        </div>
        <ul className="nav-list">
          {isLoggedIn ? (
            // Links to display if logged in
            <>
              {/* <li>
              <Link to="/">
                <i className="bx bx-home"></i>
                <span className="links_name">Home</span>
              </Link>
              <span className="tooltip">Home</span>
            </li> */}
              {scoped("drp-decisiontool") && isLoggedIn && (
                <li
                  // onMouseEnter={() => setIsDRPHovered(true)}
                  // onMouseLeave={() => setIsDRPHovered(false)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Link to="/decisiontool">
                    {/* {isOpen && <i className="bx bx-calculator"></i>} */}
                    {/* <img src={calculator} alt="" /> */}
                    {!isOpen ? (
                      <span
                        style={{
                          height: 50,
                          minWidth: 50,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="custom-calculator"
                      >
                        <DRPCalculator />
                      </span>
                    ) : (
                      <i className="bx bx-calculator bx-sm"></i>
                    )}
                    <span className="links_name">DRP Decision Tool</span>
                  </Link>

                  <span className="tooltip">DRP Decision Tool</span>
                  {/* {isOpen && (
                  <span className="small ">
                    <span
                      className={`position-absolute top-0 start-50 translate-middle badge ${
                        isDRPHovered ? "bg-white text-dark" : "text-light"
                      }`}
                    >
                      DRP
                    </span>
                  </span>
                )} */}
                </li>
              )}
              {scoped("lgm-decisiontool") && isLoggedIn && (
                <li
                  style={{ marginTop: 10 }}
                  // onMouseEnter={() => setIsLGMHovered(true)}
                  // onMouseLeave={() => setIsLGMHovered(false)}
                >
                  <Link to="/lgm-decisiontool">
                    {!isOpen ? (
                      <span
                        style={{
                          height: 50,
                          minWidth: 50,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="custom-calculator"
                      >
                        <LGMCalculator />
                      </span>
                    ) : (
                      <i className="bx bx-calculator bx-sm"></i>
                    )}
                    <span className="links_name">LGM Decision Tool</span>
                  </Link>
                  <span className="tooltip">LGM Decision Tool</span>
                  {/* {!isOpen && (
                  <span className="small">
                    <span
                      className={`position-absolute top-0 start-50 translate-middle badge ${
                        isLGMHovered ? "bg-white text-dark" : "text-light"
                      }`}
                    >
                      LGM
                    </span>
                  </span>
                )} */}
                </li>
              )}
              {scoped("lrp-decisiontool") && isLoggedIn && (
                <li
                  style={{ marginTop: 10 }}
                  // onMouseEnter={() => setIsLRPHovered(true)}
                  // onMouseLeave={() => setIsLRPHovered(false)}
                >
                  <Link to="/lrp-decisiontool">
                    {!isOpen ? (
                      <span
                        style={{
                          height: 50,
                          minWidth: 50,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="custom-calculator"
                      >
                        <LRPCalculator />
                      </span>
                    ) : (
                      <i className="bx bx-calculator bx-sm"></i>
                    )}
                    <span className="links_name">LRP Decision Tool</span>
                  </Link>
                  <span className="tooltip">LRP Decision Tool</span>

                  {/* {!isOpen && (
                  <span className="small small">
                    <span
                      className={`position-absolute top-0 start-50 translate-middle badge ${
                        isLRPHovered ? "bg-white text-dark" : "text-light"
                      }`}
                    >
                      LRP
                    </span>
                  </span>
                )} */}
                </li>
              )}

              {scoped("view-hedgemaps") && isLoggedIn && (
                <li>
                  <Link to="/view-hedgeMAPs">
                    <i className="bx bx-folder"></i>
                    {/* <i classname="bx bxs-folder-open"></i> */}
                    {/* <i class='bx bxs-folder' ></i> */}
                    <span className="links_name">View HedgeMAPs</span>
                  </Link>
                  <span className="tooltip">View HedgeMAPs</span>
                </li>
              )}
              {scoped("resources") && isLoggedIn && (
                <li>
                  <Link to="/resources">
                    <i className="bx bx-link"></i>
                    <span className="links_name">Resources</span>
                  </Link>
                  <span className="tooltip">Resources</span>
                </li>
              )}

              {scoped("podcasts") && isLoggedIn && (
                <li>
                  <Link to="/podcasts">
                    <i className="bx bx-album"></i>
                    <span className="links_name">Podcasts</span>
                  </Link>
                  <span className="tooltip">Podcasts</span>
                </li>
              )}
              {scoped("producer-view-hedgemap") && isLoggedIn && (
                <li>
                  <Link to="/producer-view-hedgemaps">
                    <i className="bx bxs-file-pdf"></i>
                    <span className="links_name">View Producer Hedgemap</span>
                  </Link>
                  <span className="tooltip">View Producer Hedgemap</span>
                </li>
              )}
              {/* AGENT FUNCTIONS */}
              {(scoped("admin") || scoped("authorize-users")) && isLoggedIn && (
                <hr
                  style={{
                    backgroundColor: "#fff",
                    border: "none",
                    width: "100%",
                    height: "1px",
                  }}
                />
              )}
              {scoped("authorize-users") && isLoggedIn && (
                <li>
                  <Link to="/authorize-producers">
                    {/* <i className="bx bx-run"></i> */}
                    <i className="bx bx-user"></i>
                    <span className="links_name">Authorize Producers</span>
                    {isOpen && !hidden && unauthorized_users.length > 0 && (
                      <span
                        className="badge bg-danger ms-2"
                        style={{
                          color: "#fff",
                        }}
                      >
                        {unauthorized_users.length}
                      </span>
                    )}
                  </Link>
                  <span>
                    <span className="tooltip">
                      <span>Authorize Producers</span>
                    </span>
                    {!isOpen && unauthorized_users.length > 0 && (
                      //Style pillmyself with top right but slightly off

                      <span
                        className="badge rounded-pill bg-danger"
                        style={{ position: "absolute", top: -5, right: -5 }}
                      >
                        {unauthorized_users.length}
                      </span>
                    )}
                  </span>
                </li>
              )}

              {/* ADMIN FUNCTIONS */}
              {scoped("admin") && isLoggedIn && (
                <li>
                  <Link to="/admin">
                    {/* <i className="bx bxs-error"></i> */}
                    <i className="bx bx-lock-alt"></i>
                    <span className="links_name">Admin</span>
                  </Link>
                  <span className="tooltip">Admin</span>
                </li>
              )}
              {scoped("agent-authorize-producer-hedgemap") && isLoggedIn && (
                <li>
                  <Link to="/admin/agent-authorize-producer-hedgemap">
                    {/* <i className="bx bxs-error"></i> */}
                    <i className="bx bxs-file-pdf"></i>
                    <span className="links_name">
                      Authorize HedgeMAP Viewers
                    </span>
                  </Link>
                  <span className="tooltip">
                    Authorize User to view HedgeMAP
                  </span>
                </li>
              )}
              {scoped("update-email-subscriptions") && isLoggedIn && (
                <li>
                  <Link to="/admin/update-email-subscriptions">
                    {/* <i className="bx bxs-error"></i> */}
                    <i className="bx bxs-file-pdf"></i>
                    <span className="links_name">
                      Update Email Subscriptions
                    </span>
                  </Link>
                  <span className="tooltip">Update Email Subscriptions</span>
                </li>
              )}
              <hr
                style={{
                  backgroundColor: "#fff",
                  border: "none",
                  width: "100%",
                  height: "1px",
                }}
              />
              {isLoggedIn && (
                <li>
                  <Link to="/manage-account">
                    {/* <i className="bx bx-user"></i> */}
                    <i className="bx bx-cog"></i>
                    <span className="links_name">Manage Account</span>
                  </Link>
                  <span className="tooltip">Manage Account</span>
                </li>
              )}
            </>
          ) : (
            // Links to display whne logged out
            <>
              <li>
                <Link to="/login">
                  <i className="bx bx-log-in"></i>
                  <span className="links_name">Login</span>
                </Link>
                <span className="tooltip">Login</span>
              </li>
              {/* <li>
              <Link to="/register">
                <i className="bx bx-user-plus"></i>
                <span className="links_name">Register</span>
              </Link>
              <span className="tooltip">Register</span>
            </li> */}
            </>
          )}

          {isLoggedIn && (
            // Logout button & email at the bottom of the menu
            <>
              <li
                className="profile"
                style={
                  userType === "AGENT"
                    ? { background: "#8e2736" }
                    : { background: "rgb(31, 73, 125)" }
                }
              >
                <div className="profile-details">
                  {/* <img src="profile.jpg" alt="profileImg" /> */}
                  <div className="name_job">
                    <div className="name">{email}</div>
                  </div>
                </div>
                <Link
                  to="/logout"
                  style={
                    userType === "AGENT"
                      ? { background: "#8e2736" }
                      : { background: "rgb(31, 73, 125)" }
                  }
                >
                  <i
                    className="bx bx-log-out"
                    id="log_out"
                    style={
                      userType === "AGENT"
                        ? { background: "#8e2736" }
                        : { background: "rgb(31, 73, 125)" }
                    }
                  ></i>
                </Link>
                {/* <span className="tooltip">Logout</span> */}
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

export default SideMenu;
