import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import {
  requestVerificationEmail,
  checkEmailVerification,
} from "../../features/authActions";
import { reset } from "../../features/authSlice";

function EmailVerification() {
  const dispatch = useAppDispatch();
  const { emailVerification, email } = useAppSelector((state) => state.auth);

  const [mailSent, setMailSent] = useState(false);
  const [mailVerified, setMailVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [firstCheck, setFirstCheck] = useState(true);
  const [recheckingStatus, setRecheckingStatus] = useState(false);
  const [sendEmailConfirmationDisabled, setSendEmailConfirmationDisabled] =
    useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const sendEmail = () => {
    dispatch(requestVerificationEmail());
    startCountdown();
  };

  const startCountdown = () => {
    const countdownDuration = 60; // Set countdown time in seconds
    const endTime = Date.now() + countdownDuration * 1000; // Calculate the end time
    localStorage.setItem("emailCountdownEndTime", String(endTime)); // Save end time to localStorage

    setSendEmailConfirmationDisabled(true);
    setTimeLeft(countdownDuration);

    const timer = setInterval(() => {
      const remainingTime = Math.floor((endTime - Date.now()) / 1000);
      if (remainingTime <= 0) {
        clearInterval(timer);
        setSendEmailConfirmationDisabled(false);
        localStorage.removeItem("emailCountdownEndTime"); // Remove end time from localStorage
        setTimeLeft(0);
      } else {
        setTimeLeft(remainingTime);
      }
    }, 1000);

    return () => clearInterval(timer);
  };

  useEffect(() => {
    // Check for an existing countdown end time in localStorage on mount
    const storedEndTime = localStorage.getItem("emailCountdownEndTime");
    if (storedEndTime) {
      const remainingTime = Math.floor(
        (parseFloat(storedEndTime) - Date.now()) / 1000,
      );
      if (remainingTime > 0) {
        setTimeLeft(remainingTime);
        setSendEmailConfirmationDisabled(true);

        const timer = setInterval(() => {
          const newRemainingTime = Math.floor(
            (parseFloat(storedEndTime) - Date.now()) / 1000,
          );
          if (newRemainingTime <= 0) {
            clearInterval(timer);
            setSendEmailConfirmationDisabled(false);
            localStorage.removeItem("emailCountdownEndTime");
            setTimeLeft(0);
          } else {
            setTimeLeft(newRemainingTime);
          }
        }, 1000);

        return () => clearInterval(timer); // Clear timer on component unmount
      } else {
        localStorage.removeItem("emailCountdownEndTime");
      }
    }
  }, []);

  const checkVerification = async () => {
    if (firstCheck) {
      setFirstCheck(false);
    }
    setRecheckingStatus(true);
    await dispatch(checkEmailVerification());
    setRecheckingStatus(false);
  };

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    if (emailVerification.isVerificationSent) {
      setMailSent(true);
      setErrorMessage("");
    }

    if (
      !emailVerification.isVerificationSent &&
      emailVerification.isError &&
      emailVerification.message
    ) {
      startCountdown();
      setErrorMessage(emailVerification.message);
    }
  }, [
    emailVerification.isVerificationSent,
    emailVerification.isError,
    emailVerification.message,
  ]);

  useEffect(() => {
    if (emailVerification.isEmailVerified) {
      setMailVerified(true);
    }
  }, [emailVerification.isEmailVerified]);

  return (
    <div className="alert alert-dark m-2 p-4">
      {mailVerified ? (
        <>
          <h4 className="alert-heading">
            <span>Email confirmed!</span>
          </h4>
          <p>{email} has been verified!</p>
        </>
      ) : (
        <>
          <h4 className="alert-heading">Confirm Your Email Address</h4>
          <p>
            You have not yet confirmed your email address. Please confirm your
            email address in order to access the portal. (If you have already
            clicked the confirmation link sent to your email address, click
            "Re-check" or log out and log back in to the portal to refresh your
            verification status.)
          </p>
          <button
            disabled={
              mailSent ||
              emailVerification.isLoading ||
              sendEmailConfirmationDisabled
            }
            className="btn btn-success mx-1"
            onClick={sendEmail}
          >
            {emailVerification.isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span> Sending... </span>
              </>
            ) : sendEmailConfirmationDisabled ? (
              <>Wait {timeLeft} seconds</>
            ) : (
              <>Send Email Confirmation</>
            )}
          </button>
          <button
            className="btn btn-danger mx-1"
            onClick={checkVerification}
            disabled={emailVerification.isVerifyingEmail}
          >
            {emailVerification.isVerifyingEmail ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span> Checking... </span>
              </>
            ) : (
              <>Re-check Email Status</>
            )}
          </button>
        </>
      )}
      <div id="messages">
        {mailSent && (
          <p>
            <i>
              Email confirmation has been sent to <b>{email}</b>.
            </i>
          </p>
        )}
        {!firstCheck &&
        !emailVerification.isEmailVerified &&
        !recheckingStatus ? (
          <p className="text-danger">
            <i>
              <b>{email}</b> is still unverified!
            </i>
          </p>
        ) : (
          <p></p>
        )}
        <p>
          <i className="text-danger">{errorMessage}</i>
        </p>
      </div>
    </div>
  );
}

export default EmailVerification;
