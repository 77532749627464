import React from "react";
import {
  downloadFile,
  downloadLGMvsDRPComparison,
  downloadLGMvsLRPCattle,
  downloadLGMvsLRPSwine,
} from "../features/resourcesActions";
import { useAppDispatch } from "../../app/redux/hooks";
const Resources = () => {
  const dispatch = useAppDispatch();

  const downloadFileLocal = (e, target) => {
    dispatch(downloadFile(target));
  };

  return (
    <div className="ms-4">
      <div>
        <h2>Dairy Revenue Protection (DRP)</h2>

        <div className="ps-4 indent">
          <h5>DRP Tutorial Videos:</h5>
          <div className="ps-4">
            <i className="bx bxs-videos">
              <a
                className="indent"
                href="https://youtu.be/hVTby2qhzAw"
                target="_blank"
                rel="noreferrer"
              >
                Introduction to the DRP Decision Tool
              </a>
            </i>
            <br></br>
            <i className="bx bxs-videos">
              <a
                className="indent"
                href="https://youtu.be/OlV6Inynycc"
                target="_blank"
                rel="noreferrer"
              >
                Running a Class vs. Component Comparison
              </a>
            </i>
            <br></br>
            <i className="bx bxs-videos">
              <a
                className="indent"
                href="https://youtu.be/78eD7Z5-gIY"
                target="_blank"
                rel="noreferrer"
              >
                Reading a DRP HedgeMAP
              </a>
            </i>
          </div>

          <h5>DRP Print Resources:</h5>
          <ul className="indent">
            <li className="indent">
              <span className="fw-bold">
                Dairy Revenue Protection Practical Facts
              </span>{" "}
              (
              <button
                className="btn btn-link p-0"
                onClick={(e) => downloadFileLocal(e, "DRPPracticalFacts")}
              >
                .pdf
              </button>
              )
              <p>
                Trying to understand all the choices available with DRP and how
                the program works? Our “Practical Facts” sheet gives an overview
                of the DRP program. This sheet also explains which choices you
                will have to make when using DRP and how to evaluate the options
                available.
              </p>
            </li>
            <li className="indent">
              <span className="fw-bold">
                Dairy Revenue Protection Insurance Plan Template
              </span>{" "}
              (
              <button
                className="btn btn-link p-0"
                onClick={(e) =>
                  downloadFileLocal(e, "DRPInsurancePlanTemplate")
                }
              >
                .pdf
              </button>
              )
              <p>
                Looking to develop a plan for using DRP? Use our planning
                template as a guideline in developing your risk management
                strategy to utilize DRP insurance coverage.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-4">
        <h2>Livestock Gross Margin (LGM) / Livestock Risk Protection (LRP)</h2>
        <div className="ps-4">
          <h5>LGM / LRP Tutorial Videos:</h5>

          <div className="ps-4">
            <i className="bx bxs-videos">
              <a
                className="indent"
                href="https://youtu.be/v_BtpbbeAzQ"
                target="_blank"
                rel="noreferrer"
              >
                01 - Explaining Revenue and Margin Coverage
              </a>
            </i>
            <br></br>
            <i className="bx bxs-videos">
              <a
                className="indent"
                href="https://youtu.be/JHltufEgR8o"
                target="_blank"
                rel="noreferrer"
              >
                02 - LGM vs. LRP
              </a>
            </i>
          </div>

          <h5 className="mt-4">Livestock Program Overviews:</h5>
          <ul className="indent">
            <li className="indent">
              LRP Cattle Overview (
              <button
                className="btn btn-link p-0"
                onClick={(e) => downloadFileLocal(e, "LRPCattleOverview")}
              >
                .pdf
              </button>
              )
            </li>
          </ul>

          <h5 className="mt-4">Livestock Program Comparisons:</h5>
          <ul className="indent">
            <li className="indent">
              Comparison of LGM Dairy and Dairy RP (
              <button
                className="btn btn-link p-0"
                onClick={(e) => dispatch(downloadLGMvsDRPComparison())}
              >
                .pdf
              </button>
              )
            </li>
            <li className="indent">
              Comparison of LGM Cattle and LRP Cattle (
              <button
                className="btn btn-link p-0"
                onClick={(e) => dispatch(downloadLGMvsLRPCattle())}
              >
                .pdf
              </button>
              )
            </li>
            <li className="indent">
              Comparison of LGM Swine and LRP Swine (
              <button
                className="btn btn-link p-0"
                onClick={(e) => dispatch(downloadLGMvsLRPSwine())}
              >
                .pdf
              </button>
              )
            </li>
          </ul>

          <h5 className="mt-4">LGM / LRP Settlement FAQs:</h5>
          <ul className="indent">
            <li className="indent">
              LGM / LRP Settlement FAQ - Swine (
              <button
                className="btn btn-link p-0"
                onClick={(e) =>
                  downloadFileLocal(e, "LGMLRPSettlementFAQSwine")
                }
              >
                .pdf
              </button>
              )
            </li>
            <li className="indent">
              LGM / LRP Settlement FAQ - Cattle (
              <button
                className="btn btn-link p-0"
                onClick={(e) =>
                  downloadFileLocal(e, "LGMLRPSettlementFAQCattle")
                }
              >
                .pdf
              </button>
              )
            </li>
          </ul>

          <h5 className="mt-4">LGM Contract Months:</h5>
          <p>
            Trying to understand which futures contract months are used in the
            calculation of LGM margins? These resources explain which futures
            contracts are used in the margin calculations, and how RMA
            calculates prices for months with no futures contract.
          </p>
          <ul className="indent">
            <li className="indent">
              Swine LGM CME Months (
              <button
                className="btn btn-link p-0"
                onClick={(e) => downloadFileLocal(e, "SwineLGMCMEMonths")}
              >
                .pdf
              </button>
              )
            </li>
            <li className="indent">
              Cattle LGM CME Months (
              <button
                className="btn btn-link p-0"
                onClick={(e) => downloadFileLocal(e, "CattleLGMCMEMonths")}
              >
                .pdf
              </button>
              )
            </li>
            <li className="indent">
              Dairy LGM CME Months (
              <button
                className="btn btn-link p-0"
                onClick={(e) => downloadFileLocal(e, "DairyLGMCMEMonths")}
              >
                .pdf
              </button>
              )
            </li>
          </ul>

          <h5 className="mt-4">LGM Settlement Calendar:</h5>
          <p>
            Beginning with the 2025 Crop year, LGM uses a new methodology for
            calculating actual market prices. The settlement calendars linked
            below show the relevant settlement dates used to calculate the final
            average LGM settlement price, along with the underlying futures
            contract from which those settlement prices will be drawn.
          </p>
          <ul className="indent">
            <li className="indent">
              LGM Settlement Calendar - Swine (
              <button
                className="btn btn-link p-0"
                onClick={(e) =>
                  downloadFileLocal(e, "LGMSettlementCalendarSwine")
                }
              >
                .pdf
              </button>
              )
            </li>
            <li className="indent">
              LGM Settlement Calendar - Cattle (
              <button
                className="btn btn-link p-0"
                onClick={(e) =>
                  downloadFileLocal(e, "LGMSettlementCalendarCattle")
                }
              >
                .pdf
              </button>
              )
            </li>
            <li className="indent">
              LGM Settlement Calendar - Dairy (
              <button
                className="btn btn-link p-0"
                onClick={(e) =>
                  downloadFileLocal(e, "LGMSettlementCalendarDairy")
                }
              >
                .pdf
              </button>
              )
            </li>
          </ul>

          <h5 className="mt-4">Historical LGM Margin vs. Revenue by Month</h5>
          <p>
            Curious how LGM insurance stacks up against traditional CME
            contracts? These charts are provided to help compare using the LGM
            index versus traditional CME contracts for the purpose of hedging
            revenue risk. Displayed in the charts are the daily prices for both
            revenue and LGM for the last 250 Days before expiration for each
            month.{" "}
            <span className="font-italic">
              (NOTE: LGM is not offered every day for which data is displayed in
              these charts.)
            </span>
          </p>
          <p>
            The files below are for the year 2020. Contact your Ag Hedge Desk
            representative if you are interested in acquiring data for
            additional crop years.
          </p>
          <ul className="indent">
            <li>
              <span className="fw-bold">
                LGM Dairy vs. CME Class III Milk Futures
              </span>
              <ul className="indent">
                <li className="">
                  <span>
                    LGM Dairy (
                    <button
                      className="btn btn-link p-0"
                      onClick={(e) =>
                        downloadFileLocal(e, "LGMDairyvsCMEClass3")
                      }
                    >
                      .zip
                    </button>
                    )
                  </span>
                </li>
              </ul>
            </li>
            <li className="mt-4">
              <span className="fw-bold">
                LGM Cattle vs. CME Live Cattle Futures
              </span>
              <ul className="indent">
                <li className="">
                  <span>
                    LGM Cattle - Calf (
                    <button
                      className="btn btn-link p-0"
                      onClick={(e) =>
                        downloadFileLocal(e, "LGMCattleCalfvsCMELiveCattle")
                      }
                    >
                      .zip
                    </button>
                    )
                  </span>
                </li>
                <li className="">
                  <span>
                    LGM Cattle - Yearling (
                    <button
                      className="btn btn-link p-0"
                      onClick={(e) =>
                        downloadFileLocal(e, "LGMCattleYearlingvsCMELiveCattle")
                      }
                    >
                      .zip
                    </button>
                    )
                  </span>
                </li>
              </ul>
            </li>
            <li className="mt-4">
              <span className="fw-bold">
                LGM Swine vs. CME Lean Hog Futures
              </span>
              <ul className="indent">
                <li className="">
                  <span>
                    LGM Swine - Farrow-to-Finish (
                    <button
                      className="btn btn-link p-0"
                      onClick={(e) =>
                        downloadFileLocal(e, "LGMSwineFarrowvsCMELeanHogs")
                      }
                    >
                      .zip
                    </button>
                    )
                  </span>
                </li>
                <li className="">
                  <span>
                    LGM Swine - Finishing (
                    <button
                      className="btn btn-link p-0"
                      onClick={(e) =>
                        downloadFileLocal(e, "LGMSwineFinishingvsCMELeanHogs")
                      }
                    >
                      .zip
                    </button>
                    )
                  </span>
                </li>
                <li className="">
                  <span>
                    LGM Swine - SEW (
                    <button
                      className="btn btn-link p-0"
                      onClick={(e) =>
                        downloadFileLocal(e, "LGMSwineSEWvsCMELeanHogs")
                      }
                    >
                      .zip
                    </button>
                    )
                  </span>
                </li>
              </ul>
            </li>
          </ul>

          <h5 className="mt-4">LGM Historical Analyses:</h5>
          <p></p>
          <ul className="indent">
            <li className="indent">
              LGM Cattle Calf (
              <button
                className="btn btn-link p-0"
                onClick={(e) =>
                  downloadFileLocal(e, "HistoricalAnalysisLGMCattleCalf")
                }
              >
                .pdf
              </button>
              )
            </li>
            <li className="indent">
              LGM Cattle Yearling (
              <button
                className="btn btn-link p-0"
                onClick={(e) =>
                  downloadFileLocal(e, "HistoricalAnalysisLGMCattleYearling")
                }
              >
                .pdf
              </button>
              )
            </li>
            <li className="indent">
              LGM Dairy Minimum Feed (
              <button
                className="btn btn-link p-0"
                onClick={(e) =>
                  downloadFileLocal(e, "HistoricalAnalysisLGMDairyMinimumFeed")
                }
              >
                .pdf
              </button>
              )
            </li>
            <li className="indent">
              LGM Swine Farrow to Finish (
              <button
                className="btn btn-link p-0"
                onClick={(e) =>
                  downloadFileLocal(
                    e,
                    "HistoricalAnalysisLGMSwineFarrowToFinish",
                  )
                }
              >
                .pdf
              </button>
              )
            </li>
            <li className="indent">
              LGM Swine Finishing (
              <button
                className="btn btn-link p-0"
                onClick={(e) =>
                  downloadFileLocal(e, "HistoricalAnalysisLGMSwineFinishing")
                }
              >
                .pdf
              </button>
              )
            </li>
            <li className="indent">
              LGM Swine SEW (
              <button
                className="btn btn-link p-0"
                onClick={(e) =>
                  downloadFileLocal(e, "HistoricalAnalysisLGMSwineSEW")
                }
              >
                .pdf
              </button>
              )
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Resources;
