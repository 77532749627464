import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { setLGMType } from "../features/LGMSlice";
import { LGMQuoteType, LGMCommodityType } from "@agdeveloper/domain";
import LGMCattleCornTargetWeight from "./LGMCattleCornTargetWeight";
import LGMCattleFeederCattleTargetWeight from "./LGMCattleFeederCattleTargetWeight";
import LGMCattleLiveCattleTargetWeight from "./LGMCattleLiveCattleTargetWeight";
import {
  setLGMCattleFeederCattleTargetWeight,
  setLGMCattleCornTargetWeight,
  setLGMCattleliveCattleTargetWeight,
} from "../features/LGMSlice";

const DEFAULT_VALUES_LGM_FEEDER_CATTLE_TARGET_WEIGHT = {
  [LGMQuoteType.YEARLING_FINISHING]: 7.5,
  [LGMQuoteType.CALF_FINISHING]: 5.5,
};
const DEFAULT_VALUES_LGM_LIVE_CATTLE_TARGET_WEIGHT = {
  [LGMQuoteType.YEARLING_FINISHING]: 12.5,
  [LGMQuoteType.CALF_FINISHING]: 11.5,
};
const DEFAULT_VALUES_LGM_CORN_TARGET_WEIGHT = {
  [LGMQuoteType.YEARLING_FINISHING]: 50,
  [LGMQuoteType.CALF_FINISHING]: 52,
};

const CATTLE_CORN_TARGET_WEIGHT_MAP = {
  [LGMQuoteType.CALF_FINISHING]: {
    min: 50,
    max: 75,
  },
  [LGMQuoteType.YEARLING_FINISHING]: {
    min: 50,
    max: 85,
  },
};
const FEEDER_CATTLE_TARGET_WEIGHT_MAP = {
  [LGMQuoteType.CALF_FINISHING]: {
    min: 4,
    max: 6,
  },
  [LGMQuoteType.YEARLING_FINISHING]: {
    min: 6,
    max: 9,
  },
};
const CATTLE_LIVE_CATTLE_TARGET_WEIGHT_MAP = {
  [LGMQuoteType.CALF_FINISHING]: {
    min: 11,
    max: 13,
  },
  [LGMQuoteType.YEARLING_FINISHING]: {
    min: 12,
    max: 15,
  },
};

const LGMCattleInputs = () => {
  const dispatch = useAppDispatch();
  const { policy, type } = useAppSelector((state) => state.lgm.input);
  const _resetToDefaultValues = () => {
    // Dispatch the default values for each weight
    dispatch(
      setLGMCattleFeederCattleTargetWeight(
        DEFAULT_VALUES_LGM_FEEDER_CATTLE_TARGET_WEIGHT[type.value],
      ),
    );
    dispatch(
      setLGMCattleliveCattleTargetWeight(
        DEFAULT_VALUES_LGM_LIVE_CATTLE_TARGET_WEIGHT[type.value],
      ),
    );
    dispatch(
      setLGMCattleCornTargetWeight(
        DEFAULT_VALUES_LGM_CORN_TARGET_WEIGHT[type.value],
      ),
    );
  };
  const _minFeedMaxFinish = () => {
    dispatch(
      setLGMCattleFeederCattleTargetWeight(
        FEEDER_CATTLE_TARGET_WEIGHT_MAP[type.value].min,
      ),
    );
    dispatch(
      setLGMCattleliveCattleTargetWeight(
        CATTLE_LIVE_CATTLE_TARGET_WEIGHT_MAP[type.value].max,
      ),
    );
    dispatch(
      setLGMCattleCornTargetWeight(
        CATTLE_CORN_TARGET_WEIGHT_MAP[type.value].min,
      ),
    );
  };

  return (
    <>
      {policy.value === LGMCommodityType.CATTLE && (
        <div className="container-fluid">
          <div className="row">
            <div className="col me-1 mt-1 p-0">
              <LGMCattleCornTargetWeight
                DEFAULT_VALUES={DEFAULT_VALUES_LGM_CORN_TARGET_WEIGHT}
                TARGET_WEIGHT_MAP={CATTLE_CORN_TARGET_WEIGHT_MAP}
              />
            </div>
            <div className="col me-1 mt-1 p-0">
              <LGMCattleFeederCattleTargetWeight
                DEFAULT_VALUES={DEFAULT_VALUES_LGM_FEEDER_CATTLE_TARGET_WEIGHT}
                TARGET_WEIGHT_MAP={FEEDER_CATTLE_TARGET_WEIGHT_MAP}
              />
            </div>
            <div className="col me-1 mt-1 p-0">
              <LGMCattleLiveCattleTargetWeight
                DEFAULT_VALUES={DEFAULT_VALUES_LGM_LIVE_CATTLE_TARGET_WEIGHT}
                TARGET_WEIGHT_MAP={CATTLE_LIVE_CATTLE_TARGET_WEIGHT_MAP}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col me-1 mt-3 mb-3 p-0 text-center">
              <strong>Actions: </strong>
              <button
                type="button"
                className="btn btn-secondary me-2"
                onClick={_resetToDefaultValues}
              >
                Default
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={_minFeedMaxFinish}
              >
                Min Feed/Max Finish
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LGMCattleInputs;
